import React from "react";
import '../styles/Footer.css';

const Footer = () => {

    return (
        <>
            <div className="footer">
                <div className="copywright">
                    Timesaverz website is express property of InfernOwl, LSJay, and Hoagiepops<br />
                    Website created by Chris C. aka InfernOwl c.2024<br />
                    Please do not make fun of this site. I grew up during Myspace. I will cry &#128517;
                </div>
            </div>
        </>
    )
}

export default Footer;